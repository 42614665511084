import React from "react";
import "./MainSection.css";
import logo from "../../images/techhabiles.svg";

function MainSection(props) {
  let count = props.count;
  return (
    <div
      className={count === 0 ? "main_section_without_sidebar" : "main_section"}
    >
      <img src={logo} width="96%" height="96%" alt="Logo" />
    </div>
  );
}

export default MainSection;
