import React from "react";
import "./SideNav.css";

function SideNav(props) {
  let itemList = props.items;
  return (
    <div
      className={itemList.length === 0 ? "side_nav_without_items" : "side_nav"}
    >
      <div className="list-group">
        {itemList.map((item, index) => (
          <span className="list-item" key={index}>
            {item}
          </span>
        ))}
      </div>
    </div>
  );
}

export default SideNav;
