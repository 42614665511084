import React, { useState } from "react";
import "../../../src/App.css";
import "./Header.css";

import logo from "../../images/techhabiles.svg";

function Header(props) {
  const { items, onHeaderClicked } = props;

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const onClick = (item, index) => {
    setSelectedIndex(index);
    onHeaderClicked(item, index);
  };
  // let items = props.items; //
  return (
    <div className="header">
      <div
        className={
          selectedIndex === -1 ? "header_logo_no_pointer" : "header_logo"
        }
      >
        <img
          src={logo}
          width="140"
          height="140"
          alt="Home"
          onClick={() => onClick("", -1)}
        />
      </div>
      <div className="header_menu">
        {items.map((item, index) => (
          <span
            className="main_menu_item"
            key={index}
            onClick={() => onClick(item, index)}
          >
            {item}
          </span>
        ))}
      </div>
    </div>
  );
}

export default Header;
