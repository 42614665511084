import "./App.css";
import Header from "./components/header/Header";
import SideNav from "./components/sidenav/SideNav";
import MainSection from "./components/MainSection/MainSection";
import Footer from "./components/footer/Footer";
import { useState } from "react";

function App() {
  let menuItems = ["Services", "About Us", "Contact Us"];
  const [items, setItems] = useState([]);

  const onMenuClicked = (item, index) => {
    console.log(" items at " + index + " clicked ");
    if (index === 0) {
      setItems(["Android", "iOS", "React Native", "Flutter"]);
    } else {
      setItems([]);
    }
  };
  return (
    <div className="App">
      <Header items={menuItems} onHeaderClicked={onMenuClicked} />
      <SideNav items={items} />
      <MainSection count={items.length} />
      <Footer />
    </div>
  );
}

export default App;
